import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "tab-content",
  id: "myTabContent"
}
const _hoisted_2 = {
  class: "tab-pane fade show active",
  id: "card",
  role: "tabpanel",
  "aria-labelledby": "card-tab"
}
const _hoisted_3 = {
  class: "tab-pane fade",
  id: "coin",
  role: "tabpanel",
  "aria-labelledby": "coin-tab"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CardWithdrawal = _resolveComponent("CardWithdrawal")!
  const _component_CoinWithdrawal = _resolveComponent("CoinWithdrawal")!
  const _component_HourglassLoader = _resolveComponent("HourglassLoader")!

  return (_openBlock(), _createBlock(_component_HourglassLoader, { loading: _ctx.loading }, {
    default: _withCtx(() => [
      _createElementVNode("section", null, [
        _cache[0] || (_cache[0] = _createElementVNode("div", { class: "d-flex justify-content-between align-items-center py-3" }, [
          _createElementVNode("div", { class: "d-block" }, [
            _createElementVNode("h2", { class: "h4" }, "System Withdrawals"),
            _createElementVNode("p", { class: "mb-0" }, "Below are the users withdrawals")
          ])
        ], -1)),
        _cache[1] || (_cache[1] = _createElementVNode("ul", {
          class: "nav nav-tabs",
          id: "myTab",
          role: "tablist"
        }, [
          _createElementVNode("li", {
            class: "nav-item",
            role: "presentation"
          }, [
            _createElementVNode("button", {
              class: "nav-link active",
              id: "card-tab",
              "data-bs-toggle": "tab",
              "data-bs-target": "#card",
              type: "button",
              role: "tab",
              "aria-controls": "card",
              "aria-selected": "true"
            }, "Fiat")
          ]),
          _createElementVNode("li", {
            class: "nav-item",
            role: "presentation"
          }, [
            _createElementVNode("button", {
              class: "nav-link",
              id: "coin-tab",
              "data-bs-toggle": "tab",
              "data-bs-target": "#coin",
              type: "button",
              role: "tab",
              "aria-controls": "coin",
              "aria-selected": "false"
            }, "Coins")
          ])
        ], -1)),
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_CardWithdrawal)
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_CoinWithdrawal)
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["loading"]))
}